import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createElementVNode("span", {
            class: _normalizeClass(["opCurrency", _ctx.finalClass]),
            tabindex: "0",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.setModify && _ctx.setModify(...args))),
            onFocus: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.setModify && _ctx.setModify(...args)))
        }, _toDisplayString(_ctx.$filters.currency(_ctx.localValue)) + " €", 35), [
            [_vShow, !_ctx.modify]
        ]),
        _withDirectives(_createElementVNode("input", {
            ref: "inputCurrency",
            class: _normalizeClass(["opCurrency", _ctx.class]),
            type: "number",
            step: "0.01",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.localValue) = $event)),
            onInput: _cache[3] || (_cache[3] = ($event) => (_ctx.emitModel(_ctx.localValue))),
            onBlur: _cache[4] || (_cache[4] = ($event) => (_ctx.modify = false))
        }, null, 34), [
            [_vShow, _ctx.modify],
            [_vModelText, _ctx.localValue]
        ])
    ], 64));
}
