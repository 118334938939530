export default () => {
    return [
        {
            path: '/proposte',
            name: 'proposte',
            component: () => import('./views/proposte.vue'),
        },
        {
            path: '/proposte/:identifier',
            name: 'proposteDetail',
            component: () => import('./views/proposteDetail.vue'),
        },
        {
            path: '/proposte/print/:identifier',
            name: 'propostePrint',
            component: () => import('./views/print.vue'),
        },
    ];
};
