import store from '@/store';
export default () => {
    return [
        {
            path: '/admin/',
            name: 'admin',
            component: () => import('./views/admin.vue'),
            beforeEnter: (to, from, next) => {
                if (store.state.loginResponse.role != "Admin") {
                    return false;
                }
                else {
                    next();
                }
            }
        },
        {
            path: '/admin/centriCosto',
            name: 'adminCentriCosto',
            component: () => import('./views/centriCosto.vue'),
            beforeEnter: (to, from, next) => {
                if (store.state.loginResponse.role != "Admin") {
                    return false;
                }
                else {
                    next();
                }
            }
        },
        {
            path: '/admin/permessiInvio',
            name: 'adminPermessiInvio',
            component: () => import('./views/permessiInvio.vue'),
            beforeEnter: (to, from, next) => {
                if (store.state.loginResponse.role != "Admin") {
                    return false;
                }
                else {
                    next();
                }
            }
        },
    ];
};
