import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
let WithModel = class WithModel extends Vue {
    modelValue;
    localValue = null;
    onModelValueChange(next, prev) {
        this.localValue = next;
    }
    created() {
        this.localValue = this.modelValue;
    }
    emitModel(val) {
        this.$emit("update:modelValue", val);
    }
};
__decorate([
    Prop()
], WithModel.prototype, "modelValue", void 0);
__decorate([
    Watch('modelValue')
], WithModel.prototype, "onModelValueChange", null);
WithModel = __decorate([
    Options({})
], WithModel);
export default WithModel;
