export function JsonClone(item) {
    return JSON.parse(JSON.stringify(item));
}
export function getUrlParameter(sParam) {
    var url = window.location.search.substring(1), urlvariables = url.split('&'), parameterName, i;
    for (i = 0; i < urlvariables.length; i++) {
        parameterName = urlvariables[i].split('=');
        if (parameterName[0].toLowerCase() === sParam.toLowerCase()) {
            return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
        }
    }
}
export function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
}
