import Axios from 'axios';
class _CommonServices {
    showSpinnerCb = () => { };
    hideSpinnerCb = () => { };
    unauthorizedCb = () => { };
    defaultGet(url, params, _showSpinner = true) {
        let req = {
            params
        };
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.get(url, req)
                .then(x => {
                resolve(x.data);
            }).catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    defaultPost(url, data, config, _showSpinner = true) {
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    uploadFileToUrl(url, params, onUploadProgress, _showSpinner = true) {
        var data = new FormData();
        if (params) {
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        var config = {
            onUploadProgress: function (ev) {
                if (typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                resolve(x.data);
            })
                .catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    setAuthToken(token) {
        Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('authtoken', token);
    }
    getAuthToken() {
        return window.localStorage.getItem('authtoken');
    }
    destroyToken() {
        Axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('authtoken');
    }
    constructor() {
        let token = window.localStorage.getItem('authtoken');
        if (token) {
            this.setAuthToken(token);
        }
        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (!error)
                return Promise.reject();
            if (!error.response)
                return Promise.reject(error);
            if (error.response.status == 401) {
                this.unauthorizedCb();
            }
            else if (error.response.status[0] == 5) {
                console.log("500");
                console.log(error);
            }
            else {
                if (error.response.data)
                    return Promise.reject(error.response.data);
                else
                    return Promise.reject(error);
            }
        });
    }
}
export let CommonServices = new _CommonServices();
