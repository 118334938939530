class _StorageServices {
    readFromLocalStorage(lsName) {
        let data = window.localStorage.getItem(lsName);
        if (data) {
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    setOpTableFilters(key, filters) {
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key) {
        return this.readFromLocalStorage('_op_' + key);
    }
    getLoginResponse() {
        return this.readFromLocalStorage('loginResponse');
    }
    setLoginResponse(loginResponse) {
        window.localStorage.setItem('loginResponse', JSON.stringify(loginResponse));
    }
    getCustomerTaskFilter() {
        return this.readFromLocalStorage('customerTaskFilter');
    }
    setCustomerTaskFilter(filter) {
        window.localStorage.setItem('customerTaskFilter', JSON.stringify(filter));
    }
    getEstateId() {
        return this.readFromLocalStorage('EstateId');
    }
    setEstateId(daysFilter) {
        window.localStorage.setItem('EstateId', JSON.stringify(daysFilter));
    }
    getQueryParameters() {
        return this.readFromLocalStorage('queryParameters');
    }
    setQueryParameters(parameters) {
        window.localStorage.setItem('queryParameters', JSON.stringify(parameters));
    }
}
export let StorageServices = new _StorageServices();
