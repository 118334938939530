import { appMode } from '@/config';
import * as OM from '@/model';
import * as CONST from '@/const';
import { StorageServices } from '@/services/StorageServices';
import { getUrlParameter } from '@/utils';
import { createStore } from 'vuex';
let storeState = {
    loginResponse: StorageServices.getLoginResponse() || new OM.LoginResponse(),
    consts: {
        enabledLanguages: [],
    },
    anagraficheUtente: [],
    beforeNavCbs: [],
    afterNavCbs: [],
    globalSpinner: 0,
    estateid: StorageServices.getEstateId() || "",
    isTestMode: () => {
        return appMode == 'local' || appMode == 'test';
    },
    getParameters: {
        apiClaim: '',
        customerName: '',
        customerWebId: '',
        groupId: '',
        language: '',
        parameterCacheId: '',
        timestamp: '',
        estateId: '',
        businessUnit: '',
        userId: '',
        signature: '',
    },
    isAdmin: () => {
        return store.state.loginResponse.role == CONST.UserRoles.Admin;
    },
    centriDiCosto: []
};
const GET_PARAMETER_APICLAIM = 'apiClaim';
const GET_PARAMETER_CUSTOMERNAME = 'customerName';
const GET_PARAMETER_CUSTOMERWEBID = 'customerWebId';
const GET_PARAMETER_GROUPID = 'groupId';
const GET_PARAMETER_LANGUAGE = 'language';
const GET_PARAMETER_PARAMETERCACHEID = 'parameterCacheId';
const GET_PARAMETER_TIMESTAMP = 'timestamp';
const GET_PARAMETER_ESTATEID = 'estateId';
const GET_PARAMETER_BUSINESSUNIT = 'businessUnit';
const GET_PARAMETER_USERID = 'userId';
const GET_PARAMETER_SIGNATURE = 'signature';
storeState.getParameters.apiClaim = getUrlParameter(GET_PARAMETER_APICLAIM);
storeState.getParameters.customerName = getUrlParameter(GET_PARAMETER_CUSTOMERNAME);
storeState.getParameters.customerWebId = getUrlParameter(GET_PARAMETER_CUSTOMERWEBID);
storeState.getParameters.groupId = getUrlParameter(GET_PARAMETER_GROUPID);
storeState.getParameters.language = getUrlParameter(GET_PARAMETER_LANGUAGE);
storeState.getParameters.parameterCacheId = getUrlParameter(GET_PARAMETER_PARAMETERCACHEID);
storeState.getParameters.timestamp = getUrlParameter(GET_PARAMETER_TIMESTAMP);
storeState.getParameters.estateId = getUrlParameter(GET_PARAMETER_ESTATEID);
storeState.getParameters.businessUnit = getUrlParameter(GET_PARAMETER_BUSINESSUNIT);
storeState.getParameters.userId = getUrlParameter(GET_PARAMETER_USERID);
storeState.getParameters.signature = getUrlParameter(GET_PARAMETER_SIGNATURE);
let vuestore = {
    state: storeState,
};
let store = createStore(vuestore);
export default store;
