import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/op-table.css';
import '@/css/style.css';
import DIRECTIVES from '@/directives';
import FILTERS from '@/filters';
import OpModal from '@/plugins/modalPlugin';
import { CommonServices } from './services/CommonServices';
const app = createApp(App)
    .use(store)
    .use(router)
    .use(OpModal);
for (let prop in DIRECTIVES) {
    app.directive(prop, DIRECTIVES[prop]);
}
app.config.globalProperties.$filters = FILTERS;
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, component.default || component);
});
app.mount('#app');
CommonServices.showSpinnerCb = () => {
    store.state.globalSpinner++;
};
CommonServices.hideSpinnerCb = () => {
    store.state.globalSpinner--;
    if (store.state.globalSpinner < 0) {
        store.state.globalSpinner = 0;
    }
};
