function directiveBindFn(el, binding, vnode, regExpr, classSuffix) {
    if (binding.value != undefined && !binding.value)
        return;
    el.classList.add('v-untouched');
    let vmodelValue = binding.value;
    //if(vmodelValue == undefined || vmodelValue == null || vmodelValue == '')
    //    return;
    if (checkFunction(vmodelValue, regExpr)) {
        el.classList.add('v-valid-' + classSuffix);
    }
    else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function directiveUpdateFn(el, binding, vnode, regExpr, classSuffix) {
    if (binding.value === undefined || binding.value === binding.oldValue)
        return;
    el.classList.remove('v-untouched');
    el.classList.remove('v-invalid-' + classSuffix);
    el.classList.remove('v-valid-' + classSuffix);
    if (binding.value != undefined && !binding.value) {
        return;
    }
    let vmodelValue = binding.value;
    if (checkFunction(vmodelValue, regExpr)) {
        el.classList.add('v-valid-' + classSuffix);
    }
    else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function checkFunction(val, regExp) {
    if (val === null || val === undefined)
        return false;
    val = val.toString().trim();
    if (!regExp) {
        return !!val;
    }
    else {
        if (regExp.test(val)) {
            return true;
        }
        else {
            return false;
        }
    }
}
export default {
    required: {
        beforeMount: (el, binding, vnode) => {
            directiveBindFn(el, binding, vnode, null, 'required');
        },
        updated: (el, binding, vnode) => {
            directiveUpdateFn(el, binding, vnode, null, 'required');
        }
    },
    number: {
        beforeMount: (el, binding, vnode) => {
            let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm);
            directiveBindFn(el, binding, vnode, regExp, 'number');
        },
        updated: (el, binding, vnode) => {
            let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm);
            directiveUpdateFn(el, binding, vnode, regExp, 'number');
        }
    },
    telephone: function (el, binding, vnode) {
        el.classList.remove('v-invalid-telephone');
        el.classList.remove('v-valid-telephone');
        let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
        let vmodelValue = vnode.data.directives[vmodelIndex].value;
        if (el.tagName.toLowerCase() != 'input' && el.tagName.toLowerCase() != 'select') {
            console.error('v-telephone directive is not on tag input or select, but on tag ' + el.tagName + ". Se è una textarea aggiusta la cosa");
            return;
        }
        let found = vmodelValue.match(telephoneRegex);
        if (!found) {
            el.classList.add('v-invalid-telephone');
        }
    },
    date: function (el, binding, next, prev) {
        if (el.tagName.toLowerCase() != 'input') {
            console.error('v-date directive must be used on input tags. use type="date"');
            return;
        }
        if (el.getAttribute('type') != 'date') {
            console.error('v-date directive must be used on input with type="date"');
            return;
        }
        let val = binding.value;
        if (val) {
            el.value = validateDate(val);
        }
    },
};
export let telephoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
function validateDate(inputDate) {
    let date = new Date(inputDate);
    if (isValidDate(date)) {
        let years = date.getFullYear().toString();
        while (years.length < 4) {
            years = "0" + years;
        }
        let months = (date.getMonth() + 1).toString();
        if (months.length < 2)
            months = "0" + months;
        let days = date.getDate().toString();
        if (days.length < 2)
            days = "0" + days;
        return years + "-" + months + "-" + days;
    }
}
export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
