export class LoginVm {
    email;
    password;
}
export class SearchAnagraficaVm {
    nome;
    cognome;
    codiceFiscale;
    ragioneSociale;
    partitaIva;
    matricola;
    constructor() {
        this.nome = "";
        this.cognome = "";
        this.codiceFiscale = "";
        this.ragioneSociale = "";
        this.partitaIva = "";
        this.matricola = "";
    }
}
