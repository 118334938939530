import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("input", {
        type: "file",
        onChange: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args))),
        accept: _ctx.accept
    }, null, 40, _hoisted_1));
}
