import { renderSlot as _renderSlot, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-735d2336"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "_v-autocomplete-container",
    ref: "autocompleteContainer"
};
const _hoisted_2 = ["readonly"];
const _hoisted_3 = {
    class: "_v-autocomplete-list",
    ref: "autocompleteList"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", { search: _ctx.search }, () => [
            _withDirectives(_createElementVNode("input", {
                type: "search",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.search.value) = $event)),
                autocomplete: "false",
                ref: "searchInput",
                readonly: _ctx.readonly,
                onBlur: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.hideList && _ctx.hideList(...args))),
                placeholder: "Clicca e scrivi per cercare"
            }, null, 40, _hoisted_2), [
                [_vModelText, _ctx.search.value]
            ])
        ], true),
        _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["_v-autocomplete-list-item", { 'active': _ctx.selectedIndex == i }]),
                    key: 'listItem' + i,
                    onClick: ($event) => (_ctx.setResult(item))
                }, [
                    _renderSlot(_ctx.$slots, "list-item", { item: item }, () => [
                        _createTextVNode(_toDisplayString(item), 1)
                    ], true)
                ], 10, _hoisted_4));
            }), 128))
        ], 512)
    ], 512));
}
