import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "opModal alertModal" };
const _hoisted_2 = {
    key: 0,
    class: "header"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = { class: "body" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "mt-3 mw-700" };
const _hoisted_7 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", { innerHTML: _ctx.title }, null, 8, _hoisted_3)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-block btn-primary",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.ok && _ctx.ok(...args))),
                    ref: "okButton"
                }, [
                    _createElementVNode("span", { innerHTML: _ctx.okText }, null, 8, _hoisted_7)
                ], 512)
            ])
        ])
    ]));
}
