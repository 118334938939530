import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
        items: _ctx.items,
        filter: _ctx.filter,
        itemsPerPage: _ctx.itemsPerPage,
        ref: "opTableRef",
        "op-name": _ctx.opName,
        onFilteadrsCleared: _ctx.setFilters,
        showUpperPagination: _ctx.showUpperPagination,
        showLowerPagination: _ctx.showLowerPagination,
        totalItemsCount: _ctx.totalItemsCount,
        onSearch: _ctx.search,
        "clear-filters": _ctx.clearFilters,
        filtersFn: _ctx.filtersFn
    }, {
        default: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "default", {
                items: slotProps.items
            })
        ]),
        _: 3
    }, 40, ["items", "filter", "itemsPerPage", "op-name", "onFilteadrsCleared", "showUpperPagination", "showLowerPagination", "totalItemsCount", "onSearch", "clear-filters", "filtersFn"]));
}
