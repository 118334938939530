class Enum {
    static GetAll() {
        let ris = [];
        for (let p in this) {
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary() {
        let ris = {};
        for (let p in this) {
            ris[p] = this[p];
        }
        return ris;
    }
}
export class ModalitaPagamento extends Enum {
    static Assegno = "Assegno";
    static Bonifico = "Bonifico";
}
export class Segnalatori extends Enum {
    static Si = "Si";
    static No = "No";
    static Nd = "Nd";
}
export class SiNo extends Enum {
    static Si = "Si";
    static No = "No";
}
export class StatoTrattativa extends Enum {
    static Incerta = "Incerta";
    static Inviata = "Inviata";
    static NonAccettata = "Non accettata";
    static InAttesaInvio = "In attesa invio";
    static InErrore = "In errore";
    static Certa = "Certa";
    static Incassata = "Incassata";
    static Rifiutata = "Rifiutata";
    static Annullata = "Annullata";
    static Chiusa = "Chiusa";
    static Scaduta = "Scaduta";
    static NonInviata = "Non inviata";
}
export class TipoPagamento extends Enum {
    static PrimoVersamento = "Primo versamento";
    static VersamentoIntegrativo = "Versamento integrativo";
    static Preliminare = "Preliminare";
    static Rogito = "Rogito";
    static Mutuo = "Mutuo";
    static Leasing = "Leasing";
    static ContrattoDiLocazione = "Contratto di locazione";
}
export class TipoProvvigione extends Enum {
    static Percentuale = "%";
    static Valore = "A valore";
}
export class UserRoles {
    static Admin = "Admin";
    static User = "User";
}
