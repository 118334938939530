import proposta from '@/views/proposta';
import { createRouter, createWebHashHistory } from 'vue-router';
import movimenti from '@/views/movimenti';
import admin from '@/views/admin';
const routes = [
    {
        path: '/',
        name: 'root',
        redirect: '/proposte'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue')
    },
    ...proposta.routes(),
    ...movimenti.routes(),
    ...admin.routes(),
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;
